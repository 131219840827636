var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoading)?_c('loading'):(_vm.isError)?_c('error'):(!_vm.subPermission.store || !_vm.subPermission.update)?_c('content-not-view'):_c('b-card',{attrs:{"title":_vm.$t('g.basicInformations')}},[_c('validation-observer',{ref:"form"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('g.placeholder.enterTheNameHere')},model:{value:(_vm.form_data.name),callback:function ($$v) {_vm.$set(_vm.form_data, "name", $$v)},expression:"form_data.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.email'),"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"email","placeholder":_vm.$t('g.placeholder.enterTheEmailHere')},model:{value:(_vm.form_data.email),callback:function ($$v) {_vm.$set(_vm.form_data, "email", $$v)},expression:"form_data.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.phoneNumber'),"label-for":"phoneNumber"}},[_c('validation-provider',{attrs:{"name":"phone number","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"number","placeholder":_vm.$t('g.placeholder.enterThePhoneHere')},model:{value:(_vm.form_data.phone),callback:function ($$v) {_vm.$set(_vm.form_data, "phone", $$v)},expression:"form_data.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('g.Country'),"label-for":"country"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.country_options,"label":"position","state":errors.length > 0 ? false : null},model:{value:(_vm.form_data.country_code),callback:function ($$v) {_vm.$set(_vm.form_data, "country_code", $$v)},expression:"form_data.country_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0] ? _vm.$t(("g.alert." + (errors[0]))) : errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":_vm.isEdit ? 'success' : 'primary',"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.isEdit ? _vm.$t("g.edit") : _vm.$t("g.submit"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }